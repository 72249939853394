import React, {useCallback} from "react";
import {Col, Form, Row, Space} from "antd";
import {Button, SimpleInput} from "../../Common/components";
import {_t} from "../../Common/components/InjectIntlContext";
import {useDownloadUsersList} from "../apiHooks";
import {ExcelButton} from "../../Dashboard/components/Filters/ExcelButton";
import { ReactComponent as IconExcel } from "../../../images/icons/xlsx-file-icon.svg";
import { ReactComponent as IconCSV } from "../../../images/icons/csv-file-icon.svg";

export const FiltersWidget = ({onSubmit, initialValues, isSubmitting}) => {
  const [form] = Form.useForm();
  const onFinish = useCallback(
    async (values) => {
      const res = await onSubmit(values);
      form.resetFields();
      return res;
    },
    [form, onSubmit]
  );
  const [downloadXls, { isLoading: isXlsDownloading }] = useDownloadUsersList({
    export: "xls",
  });
  const [downloadCSV, { isLoading: isCSVDownloading }] = useDownloadUsersList({
    export: "csv",
  });


  return (
    <Form
      form={form}
      layout="horizontal"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Row align="middle" className="users-form" gutter={5}>
        <Col flex="auto">
          <Space size={5}>
            <Form.Item name="search" noStyle>
              <SimpleInput />
            </Form.Item>
            <Form.Item noStyle>
              <Button
                loading={isSubmitting}
                type="primary"
                title={_t("search")}
                htmlType="submit"
              ></Button>
            </Form.Item>
            <ExcelButton
              clickHandler={downloadXls}
              isLoading={isXlsDownloading}
              icon={<IconExcel style={{ width: "30px", height: "30px" }} />}
            />
            <ExcelButton
              clickHandler={downloadCSV}
              isLoading={isCSVDownloading}
              icon={<IconCSV style={{ width: "30px", height: "30px" }} />}
            />
          </Space>
        </Col>
      </Row>
    </Form>
  );
};
